<template>
  <div class="course-view">
    <div v-html="errorMessage" class="error-message"></div>
    <div v-html="coursedata"></div>
    <div v-html="resultdata"></div>
    <div v-html="splitdata"></div>
    <br><hr width='100%'>
    <div class="badges">
        <AppStoreBadge/>
        <GooglePlayBadgeRun/>
    </div>
  </div>
</template>

<script>
import { getCourseAndResult, reparseCourse } from '../firebase'
import AppStoreBadge from '@/components/AppStoreBadge.vue';
import GooglePlayBadgeRun from '@/components/GooglePlayBadgeRun.vue';

export default {
  name: 'View-Course',
  data() {
    return {
      coursedata: '',
      resultdata: '',
      splitdata: '',
      errorMessage: '',
    }
  },
  created() {
    const query = this.$route.query;
    const courseId = query.courseid.toLowerCase();
  
    getCourseAndResult({coursecode: courseId}).then (result => {
      const resp = result.data;
      if (resp.error != null)
      {
        if (resp.error == "Error parsing course or result data")
        {
          reparseCourse({coursecode: courseId});
        }
        this.errorMessage = resp.error;
      }
      else
      {
        this.displayCourseData(courseId, resp);
        this.displayResultTable(courseId, resp);    
    }
    
    })
    .catch((error) => {
      console.log(error.code);
    });

  },
  computed: {
    courseId() {
      // Get the query object from the route
      const query = this.$route.query;
      
      // Return the value of the "courseid" parameter
      return query.courseid;
    },
  },
  methods: {
    displayCourseData(courseId, cr)
    {
      var coursedataHtml = "<h3 style='color: var(--secondary-color)'>Course information</h3>";
      coursedataHtml += "<hr align='left' width='100%'>";
      if (cr.keydata == null)
      {
        coursedataHtml += "The course '"+ courseId+"' is not uploaded to server or have been removed from server."
      }
      else
      {
        coursedataHtml +=  "<table>";
        coursedataHtml +=  "<tr>";
        coursedataHtml +=  "<td><strong>Course name: </strong></td>";
        coursedataHtml +=  "<td>"+cr.keydata.coursename+"</td>";//"<td>".utf8_optionally($courseData->coursename)."</td>";
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Control points: </strong></td>";
        coursedataHtml +=   "<td>"+cr.keydata.nbCp+"</td>";
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Length: </strong></td>";
        coursedataHtml +=   "<td>"+Math.round(cr.keydata.length)+" meter</td>";
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Start opens: </strong></td>";
        if (cr.keydata.start_opens)
        {
          coursedataHtml +=   "<td>"+this.getStartLimit(cr.keydata.start_opens)+"</td>";
        }
        else
        {
          coursedataHtml +=   "<td>Not limited</td>";
        }
        coursedataHtml +=   "</tr>";

        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Start closes: </strong></td>";
        if (cr.keydata.start_closes)
        {
          coursedataHtml +=   "<td>"+this.getStartLimit(cr.keydata.start_closes)+"</td>";
        }
        else
        {
          coursedataHtml +=   "<td>Not limited</td>";
        }
        coursedataHtml +=   "</tr>";
          
        if (cr.keydata.daily_open)
        {
          coursedataHtml +=   "<td><strong>Opening hours: </strong></td>";
          coursedataHtml +=   "<td>"+cr.keydata.daily_open+" - "+cr.keydata.daily_close+"</td>";
          coursedataHtml +=   "</tr>";
        }

        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Created by: </strong></td>";
        coursedataHtml +=   "<td>"+cr.keydata.username+"</td>";
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Map: </strong></td>";
        coursedataHtml +=   "<td>"+cr.keydata.map+"</td>";
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Type of race: </strong></td>";
        coursedataHtml +=   "<td>"+cr.keydata.typetext+"</td>";
        coursedataHtml +=   "</tr>";
        
        if (cr.keydata.value > 0)
        {
          coursedataHtml +=   "<tr>";
          coursedataHtml +=   "<td><strong>Max value: </strong></td>";
          coursedataHtml +=   "<td>"+cr.keydata.value+" points</td>";
          coursedataHtml +=   "</tr>";
          coursedataHtml +=   "<tr>";
          coursedataHtml +=   "<td><strong>Time limit: </strong></td>";
          coursedataHtml +=   "<td>"+cr.keydata.racetimelimit+" minutes</td>";
          coursedataHtml +=   "</tr>";
          coursedataHtml +=   "<tr>";
          coursedataHtml +=   "<td><strong>Late finish: </strong></td>";
          coursedataHtml +=   "<td>"+cr.keydata.racepenalty_timelimit+" points/minute penalty</td>";
          coursedataHtml +=   "</tr>";
          coursedataHtml +=   "<tr>";
          coursedataHtml +=   "<td><strong>Manual punch: </strong></td>";
          coursedataHtml +=   "<td>"+cr.keydata.racepenalty_manual+" points penalty</td>";
          coursedataHtml +=   "</tr>";
        }
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Support allowed: </strong></td>";
        coursedataHtml +=   "<td>"+cr.keydata.support_allowed+"</td>";
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Pause allowed: </strong></td>";
        if (cr.keydata.allow_pause)
        {
          coursedataHtml +=   "<td>Yes</td>";
        }
        else
        {
          coursedataHtml +=   "<td>No</td>";
        }
        coursedataHtml +=   "</tr>";
        
        coursedataHtml +=   "<tr>";
        coursedataHtml +=   "<td><strong>Location: </strong></td>";
        coursedataHtml +=   "<td><a href='http://maps.google.com/maps?z=8&t=h&q="+cr.keydata.startLat+","+cr.keydata.startLon+"'>Show startpoint on Google Maps</a></td>";
        coursedataHtml +=   "</tr>";
          
        coursedataHtml +=   "</table>";
      }

      coursedataHtml +=   "<br>";
      this.coursedata = coursedataHtml;
    },

    displayResultTable(courseID, cr)
    {
      if (cr.keydata == null)
      {
        return;
      }

      var resultdataHtml = "<h3 style='color: var(--secondary-color)'>Result table</h3>";
      resultdataHtml += "<hr align='left' width='100%''>";

      if (cr.resulttables == null)
      {
        resultdataHtml += "No results uploaded for this course.";
        this.resultdata = resultdataHtml;
        return;
      }
      
      var resultlist = cr.resulttables.maintable;
      if (resultlist.length == 0) 
      {
        resultdataHtml += "No results uploaded for this course.";
        this.resultdata = resultdataHtml;
        return;
      }
      else
      {
        //echo count($resultfiles)." results for this course.";
        var isStdO = false;
        if (cr.keydata.type == "0")
        {
          isStdO = true;
        }
      
        var isRogaining = false;
        if (cr.keydata.type == "2")
        {
          isRogaining = true;
        }
      
        resultdataHtml += "<table class='bordertable resulttable'>";
        resultdataHtml += "<thead>";
        resultdataHtml += "<tr>";
        resultdataHtml += "<th align='left'>No.</th align='left'><th align='left'>Name</th>";
      
        if (isRogaining)
        {
          resultdataHtml += "<th align='left'>Score</th><th align='left'>Penalties</th>";
        }
        resultdataHtml += "<th align='left'>Time</th><th align='left'>Track length</th>";
        if (isStdO)
        {
          resultdataHtml += "<th align='left'>Extra<br>length</th>";
        }
        resultdataHtml += "<th align='left'>Manual<br>punches</th><th align='left'>Used<br>support</th><th align='left'>Start time</th>";
        resultdataHtml += "</tr>";
        resultdataHtml += "</thead>";
        resultdataHtml += "<tbody>";
        var hasForcedPunch = false;
        var hasDisqualified = false;
        var hasAborted = false;
        for (var i in resultlist) 
        {
          var row = resultlist[i];
          resultdataHtml += "<tr>";
          var pf = "";
          var pe = "";
          if (row.position == "DNF")
          {
            pf = "("; 
            pe = ")";
            hasAborted = true;
          }
          else if (row.position == "DSQ")
          {
            pf = "("; 
            pe = ")";
            hasDisqualified = true;
          }
          resultdataHtml += "<td>"+row.position+"</td>";
        
          resultdataHtml += "<td>"+row.username+"</td>";
        
          if (isRogaining)
          {
            resultdataHtml += "<td>"+pf+row.score+pe+"</td><td>"+pf+row.penalty+pe+"</td>";
          }
          resultdataHtml += "<td>"+pf+this.secondsToTimestring(row.time)+pe+"</td>";
          if (row.distance == "-")
          {
            resultdataHtml += "<td>- (punches only)</td>";
          }
          else
          {
            resultdataHtml += "<td>"+pf+row.distance+" m"+pe+"</td>";
          }
          if (isStdO) // Only StdO shows extra length
          {
              resultdataHtml += "<td align='center'>"+row.extralength+"</td>";
          }

          if (row.forcedpunches == null || row.forcedpunches == 0)
          {
            resultdataHtml += "<td align='center'>"+row.manualpunches+"</td>";
          }
          else
          {
            resultdataHtml += "<td align='center' bgcolor='#F7C863'>"+row.manualpunches+" ("+row.forcedpunches+" F)</td>";
            hasForcedPunch = true;
          }
          
          resultdataHtml += "<td align='center'>"+row.usedsupport+"</td>";
          resultdataHtml += "<td>"+this.getLocalTime(row.starttime)+"</td>";
          resultdataHtml += "</tr>";
        }
        resultdataHtml += "</tbody>";
        resultdataHtml += "</table>";
        resultdataHtml += "<br>";

        if (hasDisqualified)
        {
          resultdataHtml += "<small>DSQ = Disqualified due to forced manual punches</small><br>";
        }

        if (hasAborted)
        {
          resultdataHtml += "<small>DNF = Did not finish</small><br>";
        }

        resultdataHtml += "<br>";

        this.resultdata = resultdataHtml;
        if (isStdO) // Standard orienteering
        {
          this.displayResultSplitTable(cr, hasForcedPunch);
          //this.displayExportToXml(courseID)
        }
      }

    },

    displayResultSplitTable(cr, hasForcedPunch)
    {
      var resultdataHtml = "<h3 style='color: var(--secondary-color)'>Split table</h3>";
      resultdataHtml += "<hr align='left' width='100%'>";
      
      var splittable = cr.resulttables.splittable;
      var nbLegs = splittable.nblegs;
      //sortResultListForSplit(resultlist, nbLegs);
      resultdataHtml += "The leg split table shows the leg times, accumulated times and leg track lengths.<br>Position in brackets, green means best result. <br>'M' means manual punch.<br>";
      if (hasForcedPunch)
      {
        resultdataHtml += "Orange and 'F' means forced manual punch, i.e. manual punch too far from the control point.<br>";
      }
      resultdataHtml += "<small>";
      resultdataHtml += "<table class='bordertable splittable'>";
      resultdataHtml += "<thead>";
      resultdataHtml += "<tr>";
      resultdataHtml += "<th align='left'>No.</th><th align='left'>Name</th>";
      for (var i1 = 1; i1 <= nbLegs; i1++)
      {
        var cpTo = i1.toString();
        if (i1 == nbLegs)
        {
          cpTo = "Finish";
        }
        resultdataHtml += "<th>"+cpTo+"<br>"+Math.floor(cr.keydata.legs[i1-1])+"m</th>";
      }
      
      resultdataHtml += "</tr>";
      resultdataHtml += "</thead>";
      resultdataHtml += "<tbody>";
      for (var i in splittable.rows)
      {
        var row = splittable.rows[i];
        resultdataHtml += "<tr>";
        resultdataHtml += "<td rowspan='3'>"+row.position+"</td>";
        resultdataHtml += "<td rowspan='3'>"+row.username+"</td>";
        for (var j = 0; j < nbLegs; j++)
        {
          if (row.legs[j].hasleg)
          {
            if (row.legs[j].forcedpunch)
            {
              resultdataHtml += "<td bgcolor='#F7C863'>";
            }
            else if (row.legs[j].legTimePos == 1)
            {
              resultdataHtml += "<td bgcolor='#81F79F'>";
            }
            else
            {
              resultdataHtml += "<td>";
            }
            resultdataHtml += row.legs[j].legTime;
            if (row.legs[j].forcedpunch)
            {
              resultdataHtml += " F";
            }
            else if (row.legs[j].manualpunch)
            {
              resultdataHtml += " M";
            }
            resultdataHtml += " ("+row.legs[j].legTimePos+")</td>";
          }
          else
          {
            resultdataHtml += "<td>-</td>";
          }
        }
        resultdataHtml += "</tr>";
        resultdataHtml += "<tr bgcolor='#EEEEEE'>";
        for (var j2 = 0; j2 < nbLegs; j2++)
        {
          if (row.legs[j2].hasleg)
          {
            if (row.legs[j2].forcedpunch)
            {
              resultdataHtml += "<td bgcolor='#F7C863'>";
            }
            else if (row.legs[j2].accTimePos == 1)
            {
              resultdataHtml += "<td bgcolor='#A9F5BC'>";
            }
            else
            {
              resultdataHtml += "<td>";
            }
            resultdataHtml += row.legs[j2].accTime+" ("+row.legs[j2].accTimePos+")</td>";
          }
          else
          {
            resultdataHtml += "<td>-</td>";
          }
        }
        resultdataHtml += "</tr>";
        resultdataHtml += "<tr bgcolor='#DDDDDD'>";
        for (var j3 = 0; j3 < nbLegs; j3++)
        {
          if (row.legs[j3].hasleg && !row.punchesonly)
          {
            if (row.legs[j3].forcedpunch)
            {
              resultdataHtml += "<td bgcolor='#F7C863'>";
            }
            else if (row.legs[j3].legDistPos == 1)
            {
              resultdataHtml += "<td bgcolor='#CEF6D8'>";
            }
            else
            {
              resultdataHtml += "<td>";
            }
            resultdataHtml += row.legs[j3].legDist+"m ("+row.legs[j3].legDistPos+")</td>";
          }
          else
          {
            resultdataHtml += "<td>-</td>";
          }
        }
        resultdataHtml += "</tr>";
      }
      resultdataHtml += "</tbody>";
      resultdataHtml += "</table>";
      resultdataHtml += "</small>";

      this.splitdata = resultdataHtml;
    },


    getStartLimit(dbTime)
    {
      if (dbTime=="")
      {
        return "Not limited";
      }
      var dateReformat = dbTime.replace(/-/g, '/').replace('T', ' ');
      var dateO = new Date(dateReformat);
      var date = new Date(dateO.getTime() - (dateO.getTimezoneOffset() *2* 60000));
      var d1 = date.toISOString().slice(0, -1).split('.')[0];
      var localTime = d1.split('T')[0]+" "+d1.split('T')[1].substring(0, 5);
      return localTime;
    },

    secondsToTimestring(time)
    {
      var secs = time % 60;
      var mins = Math.floor(time / 60);
      var timeFormat = mins+":";
      if (secs < 10)
      {
        timeFormat += "0";
      }
      timeFormat += secs;
      return timeFormat;
    },

    getLocalTime(dbTime)
    {
      var dateReformat = dbTime.replace(/-/g, '/').replace('T', ' ');
      var dateO = new Date(dateReformat);
      var date = new Date(dateO.getTime() - (dateO.getTimezoneOffset() *2* 60000));
      var d1 = date.toISOString().slice(0, -1).split('.')[0];
      var localTime = d1.split('T')[0]+" "+d1.split('T')[1];
      return localTime;
    },
   
  },
  components: { GooglePlayBadgeRun, AppStoreBadge }
};
</script>

<style scoped>
  h3 {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 0px;
  }

  .course-view {
    padding-bottom: 60px;
  }

  .course-view >>> .bordertable {
      border-collapse: collapse;
  }
  .course-view >>> .bordertable > thead > tr > th {
      border: 1px solid black;
      border-collapse: collapse;
    padding: 3px;
    white-space: nowrap;
  }
  .course-view >>> .bordertable > tbody > tr > td {
      border: 1px solid black;
      border-collapse: collapse;
    padding: 3px;
    white-space: nowrap;
  }

  .course-view >>> .resulttable {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
  }

  .course-view >>> .splittable {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
  }

  /* Desktop View */
  .desktop-view .course-view {
    padding: 0 36px;
  }

  /* Mobile View */
  .mobile-view .course-view {
    padding: 0 10px;
  }

  .badges {
    display: flex;
    max-width: 500px;
    justify-content: space-between;
  }

  .desktop-view .badges >>> .store-badge {
    margin: 10px;
    height: 50px;
  }

  .mobile-view .badges >>> .store-badge {
    margin: 5px;
    height: 40px;
  }

</style>