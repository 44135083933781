<template>
  <div >
    <h1>Feature Comparison</h1>
    <p>Note that for Android there was previously two versions of the GPS Orienteering app: one paid, full version (GPS Orienteering) and one free version (GPS Orienteering Run). From April 2024 the two apps are merged into one free app that can be upgraded to a full Premium account.</p>
    <p>Premium is available as a 10-day pass or as a subscription, both on Android and iOS. The Premium access is connected to your GPS Orienteering user account, which means that you can mix devices (for example Android phone and iPad) with the same Premium account.</p>
    <table class="feature-table" frame=border rules=rows>
    <tbody>
      <tr class="title-text" style="background-color: #91AB54;color:white;">
        <td><strong>Feature</strong></td>
        <td class="app-title"><strong>GPS Orienteering Android</strong></td>
        <td class="app-title"><strong>GPS Orienteering iPhone</strong></td>
      </tr>
      <tr style="background-color: #e8e3de;">
        <td><strong>General</strong></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Platform requirements</td>
        <td>Android 4.4 or later</td>
        <td>iOS 14.1 or later</td>
      </tr>
      <tr>
        <td>App type</td>
        <td>Free with in-app purchase (Premium)</td>
        <td>Free with in-app purchase (Premium)</td>
      </tr>
      <tr style="background-color: #e8e3de;">
        <td><strong>Maps</strong></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Georeference map</td>
        <td>Manually or with kmz-file, kml-file or worldfile (Premium)</td>
        <td>Manually or with kmz-file, kml-file (Premium)</td>
      </tr>
      <tr>
        <td>Publish/revoke map</td>
        <td>Premium</td>
        <td>Premium</td>
      </tr>
      <tr style="background-color: #e8e3de;">
        <td><strong>Courses</strong></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Course types</td>
        <td>Standard<br>Rogaining<br>All in any order<br>Scatter</td>
        <td>Standard<br>Rogaining<br>All in any order<br>Scatter</td>
      </tr>
      <tr>
        <td>Punch methods</td>
        <td>Based on GPS position<br>Scanning QR codes</td>
        <td>Based on GPS position<br>Scanning QR codes</td>
      </tr>
      <tr>
        <td>Maze orienteering</td>
        <td>Create maze automatically (Premium)<br>Edit maze manually (Premium)<br>Run maze course</td>
        <td>Run maze course</td>
      </tr>
      <tr>
        <td>Get published course</td>
        <td>Yes. Scan QR code or enter 8 character code</td>
        <td>Yes. Scan QR code or enter 8 character code</td>
      </tr>
      <tr>
        <td>Create/edit course in app</td>
        <td>Premium</td>
        <td>Premium</td>
      </tr>
      <tr>
        <td>Import course</td>
        <td>Yes, gpx,kmz and kml format (Premium)</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Copy course</td>
        <td>Premium</td>
        <td>Premium</td>
      </tr>
      <tr>
        <td>Height profile</td>
        <td>Get height profile from cloud service. (Premium)<br>View, if included in course</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Export to printable map</td>
        <td>Premium</td>
        <td>Premium</td>
      </tr>
      <tr>
        <td>Set opening hours</td>
        <td>Start date and end date. Daily opening hours. (Premium)</td>
        <td>Start date and end date. Daily opening hours. (Premium)</td>
      </tr>
      <tr>
        <td>Publish/revoke course</td>
        <td>Yes, publish (Premium)</td>
        <td>Yes, publish (Premium)</td>
      </tr>
      <tr>
        <td>Share course code</td>
        <td>Yes. QR code and 8 character code</td>
        <td>Yes. QR code and 8 character code</td>
      </tr>
      <tr style="background-color: #e8e3de;">
        <td><strong>Run course</strong></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Punching</td>
        <td>Auto and manual based on GPS position<br>Scan QR code</td>
        <td>Auto and manual based on GPS position<br>Scan QR code</td>
      </tr>
      <tr>
        <td>View map</td>
        <td>Yes</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Voice support</td>
        <td>Premium</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Orienteering support</td>
        <td>Position on map<br>Distance/direction to next control point (Premium)<br>Course drift (Premium)</td>
        <td>Position on map</td>
      </tr>
      <tr>
        <td>LiveTrack</td>
        <td>Share current position<br>Follow runners on map in real-time (Premium)</td>
        <td>Share current position<br>Follow runners on map in real-time (Premium)</td>
      </tr>
      <tr>
        <td>Run with smart watch</td>
        <td>No</td>
        <td>Yes, Apple Watch (Premium)</td>
      </tr>
      <tr style="background-color: #e8e3de;">
        <td><strong>Results</strong></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Leaderboard</td>
        <td>Yes</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>View leg times and distances</td>
        <td>Yes</td>
         <td>Yes</td>
      </tr>
      <tr>
        <td>View tracks on map</td>
        <td>Premium</td>
        <td>Premium</td>
      </tr>
      <tr>
        <td>Replay on map</td>
        <td>Premium</td>
        <td>Premium</td>
      </tr>
      <tr>
        <td>Publish/revoke result</td>
        <td>Yes</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Import result</td>
        <td>Yes, from gpx (Premium)</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Export result</td>
        <td>Yes, to gpx</td>
        <td>Yes, to gpx</td>
      </tr>
      <tr>
        <td>Publish track on Strava</td>
        <td>Premium</td>
        <td>No</td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
export default {
  name: 'View-AppFeaturesComparison'
}
</script>
<style scoped>
  .feature-table {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
  }

  .app-title {
    text-align: left;
  }
  
  /* Desktop View */
  .desktop-view .feature-table{
    font-size: 16px;
  }

  .desktop-view .title-text {
    font-size: 20px;
  }

  /* Mobile View */
  .mobile-view .feature-table{
    font-size: 10px;
  }

  .mobile-view .title-text {
    font-size: 14px;
  }
</style>