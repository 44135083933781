<template>
  <div class="content">
    <h1>Support</h1>
    <p>If you have any question or comments, please contact us!</p>
    <p>E-mail: <a href="mailto:support@gpso.se">support@gpso.se</a></p>
    <br>
    <p>GPS Orienteering is provided by <a title="https://vidaviewtech.com/" href="https://vidaviewtech.com/">Vidaview Tech AB</a>.</p>

  </div>
</template>

<script>
export default {
  name: 'View-Support'
}
</script>