import { createRouter, createWebHistory } from 'vue-router'
import Vidaview from './views/Vidaview.vue'

const routes = [
  {
    path: '/',
    component: Vidaview
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    }
})

export default router