<template>
  <div class="content">
    <h1>Control point for GPS Orienteering</h1>
    <p>You have scanned a control point belonging to a GPS Orienteering course.</p>
    <br>
    <p>To learn more about GPS Orienteering, visit <a title="https://gpso.se/" href="https://gpso.se/">gpso.se</a></p>

  </div>
</template>

<script>
export default {
  name: 'View-QRCodeControl'
}
</script>