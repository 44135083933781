<template>
  <div class="content-vidaview">
    <br>
    Revolutionize your orienteering experience with GPS Orienteering!
    <p><img  alt="Visit GpsO" src="../assets/vidaview_logo_gb.png"/></p>
    <div>
      <a href="https://gpso.se">
        <button class="visit-gpso-button" >Visit GPS Orienteering!</button>
      </a>
    </div>
    <br>
    <div class="divider"/>
    <div class="footer">
      <p>E-mail: <a href="mailto:contact@vidaviewtech.com">contact@vidaviewtech.com</a></p>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  name: 'View-Vidaview',
}
</script>

<style>
.content-vidaview {
  background-color: #486860;
  font-size:30px;
  color: white;
  font-family: Georgia, serif;
  text-align:center;
}

.visit-gpso-button {
    font-size: 24px;
    font-family: Georgia, serif;
    color: white;
    background-color: #5f8a7f;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
    padding: 16px 32px;
    margin-top: 2px;
    margin-bottom: 50px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .visit-gpso-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: white;
 
  }

  .footer {
    margin: 0 auto; 
    padding-bottom: 60px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    font-size: 16px;
    a:link {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }

}
  </style>