<template>
  <div>
    <div v-if="!isMobile" class="desktop-view">
      <div class="left">
        <HomeIntroText />
        <div class="divider"/>
        <HomeViewCourse />
      </div>
      <div class="right">
        <img class="intro-image" src="../assets/app_preview.png" />
        <div class="badges">
          <AppStoreBadge/>
          <GooglePlayBadge/>
        </div>
        <img class="strava-image" src="../assets/strava_horiz_light.png" />
      </div>
    </div>
    <div v-else class="mobile-view">
      <HomeIntroText />
      <img class="intro-image" src="../assets/app_preview.png" />
      <div class="badges">
        <AppStoreBadge/>
        <GooglePlayBadge/>
      </div>
      <img class="strava-image" src="../assets/strava_horiz_light.png" />
      <div class="divider"/>
      <HomeViewCourse />
    </div>
  </div>
</template>
  
<script>
  import AppStoreBadge from '@/components/AppStoreBadge.vue';
  import GooglePlayBadge from '@/components/GooglePlayBadge.vue';
  import HomeIntroText from '@/components/HomeIntroText.vue';
  import HomeViewCourse from '@/components/HomeViewCourse.vue';

  export default {
    name: "View-Home",
    props: {
      isMobile: {
        type: Boolean,
        required: true,
      },
    },
    created() {

        const query = this.$route.query;
        const courseCode = query.coursecode;
        var letterNumber = /^[0-9a-zA-Z]+$/;
        if (courseCode !== undefined) {
            if (courseCode.length == 8 && courseCode.match(letterNumber)) {
                // navigate to the Course route if courseCode is 8 characters long
                this.$router.push({
                    name: "Course",
                    query: {
                        courseid: courseCode,
                    },
                });
            }
        }
    },
    components: { GooglePlayBadge, AppStoreBadge, HomeIntroText, HomeViewCourse }
}
</script>


<style scoped>
  .intro-image {
    max-width: 80%;
    max-height: 550px;
  }
  .strava-image {
    max-width: 50%;
    max-height: 40px;
    margin-top: 10px;
  }
  .divider {
    width: 60%;
    height: 1px;
    background-color: black;
    margin: 60px 0;
  }

  .badges {
    display: flex;
    justify-content: space-between;
  }

  /* Desktop View */

  .desktop-view {
    display: flex;
    justify-content: center;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1000px;
    max-width: 1500px;
    padding-top: 70px;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: center;
  }

  .desktop-view >>> .intro-text {
    font-size: 24px;
  }

  .desktop-view .badges >>> .store-badge {
    margin: 15px;
    height: 60px;
  }

  .desktop-view .view-course >>> input[type="text"] {        
    width: 400px;
    font-size: 18px;
  }

  /* Mobile View */

  .mobile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;
    padding-bottom: 15px;
  }

  .mobile-view >>> .intro-text {
    font-size: 20px;
  }

  .mobile-view .badges >>> .store-badge {
    margin: 5px;
    height: 50px;
  }

  .mobile-view .view-course {
    margin-bottom: 50px;
  }

  .mobile-view .view-course >>> input[type="text"] {        
    width: 200px;
    font-size: 12px;
  }


</style>