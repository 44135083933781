<template>
  <div  v-if="isVidaview" v-bind:class="{ 'desktop-view': !isMobile, 'mobile-view': isMobile }" >
    <router-view :is-mobile="isMobile"/>
  </div>
  <div  v-else v-bind:class="{ 'desktop-view': !isMobile, 'mobile-view': isMobile }" >
    <div class="top-bar">
      <div class="top-left">
        <button class="gpso-button" @click="handleGpsoButtonClick">
          <img class="site-icon" src="./assets/icon.png" />
          GPS Orienteering
        </button>
      </div>
      <div class="top-right">
        <!-- <button class="sign-in-button">Sign In</button> -->
        <button class="menu-button" @click="handleMenuButtonClick">Menu</button>
        <Menu :show-menu="showMenu" :is-mobile="isMobile" @close-menu="showMenu = false" />
      </div>
    </div>
    <div class="banner-image-container">
      <img class="banner-image" src="./assets/banner.png"/>
    </div>
    
    <router-view :is-mobile="isMobile"/>
    
  </div>
</template>
<script>
import Menu from './components/Menu.vue';

const MOBILE_LIMIT = 1250;

export default {
  name: 'App',
  components: {
    Menu,
  },
  data() {
    return {
      showMenu: false,
      menuButtonClicked: false,
      isMobile: false,
      isVidaview: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler);
    this.isMobile = window.innerWidth <= MOBILE_LIMIT;
    if (window.location.hostname === 'vidaviewtech.com')
    {
      this.isVidaview = true;
    }
},
  unmounted() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  mounted() {
    document.addEventListener('click', (event) => {
      // Check if the click was outside of the menu element and the menu button was not clicked
      if (!event.target.closest('.menu') && !this.menuButtonClicked) {
        this.showMenu = false;
      }
      // Reset the menu button clicked flag
      this.menuButtonClicked = false;
    });
  },
  methods: {
    handleGpsoButtonClick() {
      this.$router.push('/')
    },
    handleMenuButtonClick() {
      this.showMenu = !this.showMenu;
      this.menuButtonClicked = true;
    },
    resizeEventHandler(e) {
      this.isMobile = window.innerWidth <= MOBILE_LIMIT;
 }

  },
};
</script>

<style>
  :root {
    --primary-color: #a7a83b;
    --secondary-color: #012622;
  }

  body{
    padding: 0;
    margin: 0;
  }

  hr {
    margin-top: 0px;
  }

  h1 {
    margin-bottom: 0px;
  }

  hr {
    margin-top: 0px;
    border: 1px solid var(--primary-color);
  }

  .content{
    max-width: 1000px;
    margin: 0 auto; 
    padding-bottom: 60px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .desktop-view .content {
    font-size: 20px;
  }

  .mobile-view .content {
    font-size: 16px;
  }


</style>

<style scoped>
  .top-bar {
    display: flex;
    justify-content: space-between;
    padding: 0px 14px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: rgba(228, 228, 196, 0.7);
    backdrop-filter: blur(5px);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  }

  .top-left {
    display: flex;
    align-items: center;
  }

  .gpso-button {
    display: flex;
    align-items: center;
    font-weight: 600;
    border: none;
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
  }

  .top-right {
    display: flex;
  }

  .sign-in-button,
  .menu-button {
    border: none;
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  .sign-in-button:hover,
  .menu-button:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: var(--primary-color);
  }

  .sign-in-button {
    margin-right: 16px;
  }

  .banner-image-container {
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    padding: 0;
    margin: 0;
  }
  .banner-image {
    padding: 0;
    margin: 0;
    margin-bottom: -4px;
    z-index: 9;
  }

  .gpso-button,
  .sign-in-button,
  .menu-button {
    cursor: pointer;
  }

  

  /* Desktop View */
  .desktop-view .banner-image-container {
    margin-top: -60px;
  }
  .desktop-view  .banner-image {
    width: 100%;
  }

  .desktop-view .top-bar {
    height: 60px;
  }

  .desktop-view .site-icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .desktop-view .gpso-button {
    font-size: 24px;
  }

  

  /* Mobile View */
  .mobile-view .banner-image-container {
    margin-top: -40px;
  }
  .mobile-view .banner-image {
    height: 80px;
    width: 100%
  }
  .mobile-view .top-bar {
    height: 40px;
  }

  .mobile-view .site-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .mobile-view .gpso-button {
    font-size: 18px;
  }

</style>