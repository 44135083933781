<template>
  <div>
    <h1>Courses</h1>
    <p>The free version GPS Orienteering Run has support to download courses published by others.</p>

    <p>The Premium version of GPS Orienteering also has the support to:</p>
    <ul>
      <li>import courses</li>
      <li>create courses</li>
      <li>edit courses</li>
      <li>get the height profile</li>
      <li>export a course to a printable map</li>
      <li>set opening hours</li>
      <li>publish courses</li>
    </ul>
    <span style="text-decoration: underline;"><strong>Download published course</strong></span>

    <p>You can download courses published by others, by chosing 'Add course' in the course list view and either scan the QR code (if available) or enter the 8 character course code.</p>

    <span style="text-decoration: underline;"><strong>Import course</strong></span>

    <p>If you already have the course in a gpx-, kml- or kmz-file then you can import it into the app. This can be the case if you create the course in for example OCAD, Condes, PurplePen or Google Earth and export it to a gpx-/kml-/kmz-file.</p>
    <p>- Copy the gpx-/kml-/kmz-file to the phone.</p>
    <p>- Open the GPS Orienteering app and select 'Import course' in the course list view.</p>
    <p>- Select which course to import, give it a name and select a map to use. The map must cover the complete course. You can also import a course without a map.</p>

    <p>When you have imported the course then you may want to set course type, add a maze or change other properties, see below.</p>

    <span style="text-decoration: underline;"><strong>Create course</strong></span>

    <p>Create a new course in the following way:</p>
    <ul>
      <li>Select 'Create new course' in the course list view.</li>
      <li>Give the course a name. It can be changed later.</li>
      <li>Select the map to use. The map must be georeferenced to appear in the list.</li>
      <li>Set the type of race to use: Standard, Rogaining, All in any order or Scatter. The type of race can also be changed later. A description of the different types is given below.</li>
      <li>Set the punch method to use: GPS position or QR code. The punch method can also be changed later.</li>
      <li>Press 'Create course' to create the course and when this has been done, the map will be opened and you can start to set the control points, see 'Edit course' below.</li>
    </ul>
    <img class="img-course-create" alt="" src="../assets/course_create.png" />
    <p>Figure 1: Create new course.</p>
    <span style="text-decoration: underline;"><strong>Edit course</strong></span>

    <p>You can edit a course that you have imported or created. Downoaded courses cannot be edited.</p>

    <p>In the course view, 1) click on the map to edit the control points and 2) click on the pen icon to edit the course properties.</p>

    <img class="img-course-view" alt="" src="../assets/course_view.png" />
    <p>Figure 2: Course view</p>
    <span style="text-decoration: underline;">1. Edit control points</span>

    <p>There are different tools for editing available. Press the tool icon that corresponds to the tool:</p>
    <ul>
      <li>Add control point - 'plus' icon. Click on map to add a control point after the last one. Click and drag on the 'plus'-icon between two control points to insert a new control point between existing control points.</li>
      <li>Move control point - 'multiarrow' icon. Click and drag a control point. Change the angle of a gate by selecting the move-tool and the rotate the gate.</li>
      <li>Delete control point - 'dustbin' icon. Click on a control point to delete it.</li>
      <li>Control point type - 'O/I' icon. Click on control point to toggle between ordinary 'point control' and 'gate'. A gate is a control point that you must cross, similar to a finish line. You can rotate the gate. Gates are suitable for mountin bike orienteering on paths for example.</li>
      <li>Control point value - '5p' icon. Only for rogaining. Click on a control point to set the value.</li>
      <li>Edit maze - 'maze' icon. Only for courses that have a maze. Click on the dotted line segments to add/remove a wall segment. A new maze can be generated by selecting 'New maze' in the options menu.</li>
    </ul>
    <p>To run a course, the minimum number of control points is two (Start and Finish).</p>

    <p>The size of the symbol (triangle or ring with a cross in the middle) indicates the area where you should be to get an automatic punch.</p>

    <img class="img-course-edit" alt="" src="../assets/course_edit.png" />
    <p>Figure 3: Edit the course.</p>
    <p><img class="img-course-gates" alt="" src="../assets/edit_course_gates.jpg" width="400" height="223"/></p>
    <p>Figure 4: Course with gate controls.</p>
    <p><img class="img-course-maze" alt="" src="../assets/course_maze.png"/></p>
    <p>Figure 5: Edit course with maze</p>

    <span style="text-decoration: underline;">2. Edit course properties</span>
    <p>A number of course properties can be set and changed:</p>
    <p>- Course name</p>
    <p>- Map to use. The map must be georeferenced and the course must fit on the map.</p>
    <p>- Type of race:</p>
    <ul>
      <li>Standard orienteering - take all control points in the specified order.</li>
      <li>All in any order - begin with the start point, then take all control points in any order and last take the finish point.</li>
      <li>Rogaining - Every control point has a value. Collect as many points as possible within the time limitation by visiting the control points in any order you like.</li>
      <li>Scatter orienteering - Visit the specified number of control points in any order. Here the runner needs to punch at the most suitable control points in the best order to get the shortest time!</li>
    </ul>
    <p>- Punch method:</p>
    <ul>
      <li>GPS position - punching is done automatically when you reach the control point. The punching distance can be set between 5 and 20 meters, recommended is 15 meter.</li>
      <li>QR code - punching is done manually by scanning a QR code placed at the location of each control point. See '3. Create QR codes' below.</li>
    </ul>
    <p>- Enable/disable the maze functionality (Android). The course must contain at least 2 control points to create a maze.</p>
    <p>- Select if orienteering support is allowed during the race, or not. It is allowed by default.</p>
    <p>- Select if LiveTrack is allowed or not.</p>
    <p>- Select if pause during the race or not. Pause means that the user can 'stop the clock' and later on resume the race. To resume the race the user needs to return to the same position where it was paused.</p>
    <p>- Set opening times, i.e. when the first start is allowed (date + time) and when the last start is allowed. You can also set daily opening hours, i.e. if it is only allowed to run the course in the evening. By default a course has no limitations on the opening times.</p>

    <span style="text-decoration: underline;">3. Create QR codes</span>
    <p>A course with punch method 'QR code' needs to have a unique control code assigned to each control point. The control codes can be assigned manually for each control point or automatically numbered (starting at for example 31) by the app. The control codes can also be imported when the course is imported from for example OCAD.</p>
    <p>The QR code labels for the course are created by selecting 'Export QR codes'. This will generate files with QR code labels to be printed. Three different sizes are supported: small (5x5), medium (3x3) and large (2x2).</p>
    <p>The QR codes are generic, so you can use the same labels for several courses. It is the control code (31 etc.) that is included in the QR code that will be used for punching.</p>
    <p><img class="img-export-qr-codes" alt="" src="../assets/export_qr_codes.png"/></p>
    <p>Figure 7: Export QR codes</p>

    <span style="text-decoration: underline;"><strong>Height profile</strong></span>

    <p>The function 'Height profile' lets the app fetch height data for the course from Googles map library and show the height profile of the course. The height data is also used to adjust the height measurements from the mobile in the result profile diagram (standard-orienteering only). In the figure below the height profile for a course is shown. The blue curve contains height data for the control points, which is used to calculate minimum climb. The light blue curve contains height data for a number of points along the course (i.e. the height profile if you run straight between the control points), and is used to calculate the total climb.</p>
    <p><img class="img-height-profile" alt="" src="../assets/height_profile.png"/></p>
    <p>Figure 8: Height profile for the course 'Bokskogsrundan'</p>
    <span style="text-decoration: underline;"><strong>Publish course</strong></span>

    <p>When you have defined the course with all control points, type of course etc. then you can publish it to make it available for others.</p>

    <p>Note that a published course cannot be revoked or edited! The only things that you can change on a published course are the following:</p>
    <ul>
      <li>Opening hours: Start, end and daily opening hours.</li>
      <li>If the course shall be hidden before start</li>
      <li>If orienteering support is allowed or not.</li>
    </ul>
    <span style="text-decoration: underline;"><strong>Other functions</strong></span>

    <p>You can copy a course if you want to base a new course on an existing course. Use the function 'Copy course' and give the copy a new name.</p>

    <p>You can create a the course map in jpg-format for printing on a colour printer with the function 'Export map'. The map will contain the QR code for the course.</p>
  </div>
</template>

<script>
export default {
  name: 'View-AppFeaturesCourses'
}
</script>

<style scoped>

  /* Mobile View */
  .mobile-view .img-course-create {
    width: 60%;
  }
  .mobile-view .img-course-view {
    width: 50%;
  }
  .mobile-view .img-course-edit {
    width: 50%;
  }
  .mobile-view .img-course-gates {
    width: 100%;
  }
  .mobile-view .img-course-maze {
    width: 50%;
  }
  .mobile-view .img-export-qr-codes {
    width: 60%;
  }
  .mobile-view .img-height-profile {
    width: 100%;
  }
</style>