<template>
  <div class="content">
    <h1>Redirected during Strava authorization</h1>
    <p>If you have landed on this page it probably means that you have tried to connect GPS Orienteering with Strava.</p>
    <p>Make sure that the app 'GPS Orienteering' have enabled 'Open supported links' and that 'www.gpso.se' is checked. Then connect with Strava again.</p>
    <p>The layout view for this setting may vary from phone to phone but it should look similar to the image below.</p>
    <img src="../assets/default_app_final.png" style="margin-left: 20px"/>
  </div>
</template>

<script>
export default {
  name: 'View-Redirect_uri'
}
</script>